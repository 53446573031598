import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <nav className="App-nav">
        <h1 className="logo"><a href="/">Bridger</a></h1>
        <div className="nav-items">
          <a href="/" className="active">Dashboard</a>
          <a href="/analytics">Analytics</a>
          <a href="/transactions">Transactions</a>
          <a href="/faq">FAQ</a>
        </div>
      </nav>
      <div className="App-body">
        <div className="bg-gradient"></div>
        <div className="subtitle">
        Transfer your crypto between blockchains.
        </div>
      </div>
    </div>
  );
}

export default App;
